import { Checkbox, CheckboxProps } from "@mui/material";
import classNames from "classnames";
import "./CDataCheckbox.scss";

export interface CDataCheckboxProps extends CheckboxProps {
  inputProps?: React.InputHTMLAttributes<HTMLInputElement> & {
    [key: `data-${string}`]: unknown;
  };
}

export function CDataCheckbox(props: CDataCheckboxProps) {
  return (
    <Checkbox
      {...props}
      className={classNames("cdata-checkbox", props.className)}
      icon={<span className="checkbox-icon-unchecked" />}
      checkedIcon={<span className="checkbox-icon-checked" />}
      indeterminateIcon={<span className="checkbox-icon-indeterminate" />}
      disableRipple
    />
  );
}
